import { useEffect, useState } from "react";
import {
  openDocMiniUploadBox,
  openUploadDocumentModalAction,
  updateAllDocFiles,
} from "../../../../../store/slices/Gallery/fileUploadSlce";
import { useDispatch, useSelector } from "react-redux";
import { getPdfUrl } from "../../../../../utils/GenerateImageFromPdf";
import {
  useUploady,
  useBatchAddListener,
  useItemStartListener,
  useItemFinishListener,
  useItemFinalizeListener,
  useAbortAll,
} from "@rpldy/uploady";
import loadImage from "blueimp-load-image";

import { useParams } from "react-router-dom";
import {
  getAlbumItem,
  updateAlbumUploadedImages,
} from "../../../../../store/slices/Album/AlbumDetails";
import {
  getProfileDetails,
  updatePlanPercentage,
} from "../../../../../store/slices/userprofileSlice";
import imageCompression from "browser-image-compression";

export const useDocumentUpload = ({ mode }) => {
  const uploady = useUploady();
  const abortAll = useAbortAll();
  const dispatch = useDispatch();
  let { albumId } = useParams();
  const fileUploadState = useSelector((state) => state.fileUpload);

  const [isDraggingOverDoc, setIsDraggingOverDoc] = useState(false);
  const [docUploadState, setIsDocUploadState] = useState(false);
  const [isUploadingProgress, setIsUploadingprogress] = useState(false);

  const [allDocuments, setAllDocuments] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);

  const [docFiles, setDocFiles] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [duplicateDocuments, setDuplicateDocuments] = useState([]);
  const [invalidDocuments, setInvalidDocuments] = useState([]);

  const [docUploadIndex, setDocUploadIndex] = useState(0);
  const [docFileIndex, setDocFileIndex] = useState(0);
  const [docLength, setDocLength] = useState(0);
  const [isUploadingDoc, setIsUploadingDoc] = useState(false);
  const [isDocLimit, setIsDocLimit] = useState(false);
  const [uploadValidation, setUploadValidation] = useState();
  var allowedExtensions = new RegExp(
    /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|HEIC|heic|pdf)$/
  );
  const QA_URL = process.env.REACT_APP_APIURL_QA;
  const TEST_URL = process.env.REACT_APP_APIURL;
  const BASE_URL = process.env.REACT_APP_APIURL_LIVE;

  let api =
    process.env.REACT_APP_DEV_ENV == "PRODUTION"
      ? BASE_URL
      : process.env.REACT_APP_DEV_ENV == "DEMO"
      ? TEST_URL
      : QA_URL;

  useEffect(() => {
    if (!isUploadingDoc) {
      compressedUpload(0);
    }
  }, [docFiles]);

  useEffect(() => {
    if (
      !fileUploadState.openUploadDocumentModal?.isOpen &&
      isUploadingProgress
    ) {
      dispatch(openDocMiniUploadBox(true));
    }
  }, [fileUploadState.openUploadDocumentModal?.isOpen]);

  useItemStartListener((item) => {
    setIsUploadingprogress(true);
  });
  useItemFinishListener((item) => {
    if (fileUploadState.openUploadDocumentModal?.isOpen) {
      if (item.uploadResponse?.data?.code === 402) {
        setIsDocLimit(true);
        setUploadValidation(item.uploadResponse?.data);
        abortAll();
      }
    }
  });
  useItemFinalizeListener((item) => {
    setIsUploadingprogress(false);
    if (
      fileUploadState.openUploadDocumentModal?.isOpen &&
      docUploadIndex == documents?.length
    ) {
    }
  });

  const closeWhenDoneDoc = () => {};
  const handleImageChangeDoc = (e) => {
    handleFiles(e.target.files);
  };
  const handleDragOverDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOverDoc(false);
  };
  const handleDragOutDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggingOverDoc(true);
  };

  const handleDropDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();

    handleFiles(e.dataTransfer.files);
  };

  const openDocFiles = () => {
    document.getElementById("file_upload_input").click();
  };

  const closeDocModal = () => {
    if (docUploadIndex == documents?.length) {
      closeDocUploadModalWhenDone();
    } else {
      dispatch(
        openUploadDocumentModalAction({ data: false, path: "document" })
      );
      setIsDocLimit(false);
      setIsUploadingprogress(false);
      setDocUploadIndex(0);
      setDuplicateDocuments([]);
      setInvalidDocuments([]);
      setDocFiles([]);
      setAllDocuments([]);
      setDocuments([]);
    }
  };

  const handleFiles = (upldFiles) => {
    let tempArr = [...upldFiles];

    tempArr.forEach((item) => {
      if (
        fileUploadState.allUploadedImages.some(
          (image) => image.name === item.name
        )
      ) {
        setDuplicateDocuments((state) => [
          ...state,
          { name: item?.name, duplicate: true },
        ]);
      } else if (!allowedExtensions.test(item?.name)) {
        setInvalidDocuments((state) => [
          ...state,
          { name: item?.name, invalid: true },
        ]);
      } else {
        setDocFiles((state) => [...state, item]);
        setAllDocuments((state) => [...state, item]);
      }
    });
  };

  const addCompletedImages = (item) => {
    const updatedDocuments = documents.map((img) => {
      if (img?.file?.name === item?.file?.name) {
        return {
          ...img,
          thumbnail:
            item?.uploadResponse?.data?.result?.file_type == 2
              ? item?.uploadResponse?.data?.result?.src?.preview_url
              : item?.uploadResponse?.data?.result?.src?.thumbnail2,
        };
      }
      return img;
    });
    setDocuments(updatedDocuments);
    setDocUploadIndex((prevState) => prevState + 1);
    dispatch(updateAlbumUploadedImages(item?.uploadResponse?.data?.result));

    dispatch(updatePlanPercentage(fileUploadState?.allUploadedImages?.length));
  };
  const compressedUpload = async (index) => {
    if (!fileUploadState.openUploadModal.isOpen) {
      if (docFiles.length > 0) {
        if (isUploadingDoc === false) {
          setIsUploadingDoc(true);
        }
        if (index < docFiles.length) {
          handleImageUpload(docFiles[index]).then((r) => {
            compressedUpload(index + 1);
            setDocFileIndex((prevState) => prevState + 1);
          });
        } else {
          setDocFiles([]);
          setIsUploadingDoc(false);
        }
      }
    }
  };

  useBatchAddListener((batch) => {
    if (!fileUploadState.openUploadModal.isOpen) {
      setDocuments((state) => [...state, ...batch?.items]);
    }
  });
  useItemFinishListener((item) => {
    if (mode == "album" || mode == "document") {
      if (!fileUploadState.openUploadModal.isOpen) {
        if (item.uploadResponse?.data?.code === 200) {
          dispatch(updateAllDocFiles(item));
          addCompletedImages(item);
        } else if (item.uploadResponse?.data?.code === 403) {
          // addDuplicate(item?.file?.name);
        } else if (item.uploadResponse?.data?.code === 402) {
          setIsDocLimit(true);
          abortAll();
        }
      }
    }
  });

  const handleImageUpload = async (file) => {
    const options = {
      // maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile;
    let isCompressed = false;

    // Step 1: Compress the image
    if (file.size > 4199999) {
      try {
        compressedFile = await imageCompression(file, {
          ...options,
          alwaysKeepResolution: true,
        });
        isCompressed = compressedFile.size >= 1100000;
      } catch (error) {
        compressedFile = file;
      }
    } else {
      compressedFile = file;
    }

    // Determine which file to upload
    const cmpFile = file.size > 4199999 && isCompressed ? compressedFile : file;

    if (file?.type == "application/pdf") {
      let url = await getPdfUrl(file);
      uploady.upload(file, {
        destination: {
          url: `${api}upload/document`,
          params: {
            album_id: albumId,
            preview: url,
            isCompressed: false,
            // image_name: file?.name,
          },
          filesParamName: "file",
        },
      });
    } else {
      loadImage.parseMetaData(file, (data) => {
        if (data?.exif) {
          uploady.upload(cmpFile, {
            destination: {
              url: `${api}upload/document`,
              params: {
                exif: JSON.stringify(data?.exif?.getAll()),
                album_id: albumId,
                preview: null,
                isCompressed: false,
                // image_name: file?.name,
              },
              filesParamName: "file",
            },
          });
        } else {
          uploady.upload(cmpFile, {
            destination: {
              url: `${api}upload/document`,
              params: {
                album_id: albumId,
                isCompressed: false,
                preview: null,
                file_name: file.name,
                // image_name: file?.name,
              },
              filesParamName: "file",
            },
          });
        }
      });
    }
  };

  const handleCloseDocMiniModal = () => {
    abortAll();
    closeDocUploadModalWhenDone();
    dispatch(openDocMiniUploadBox(false));
    // dispatch(getProfileDetails());
    // setIsLimit(false);
  };

  const closeDocUploadModalWhenDone = async () => {
    // let obj = {
    //   id: albumId,
    //   page: "1",
    //   slug: "desc",
    // };
    // await dispatch(getAlbumItem(obj));
    setAllDocuments([]);
    setDocFiles([]);
    setDocuments([]);
    setInvalidDocuments([]);
    setDuplicateDocuments([]);
    dispatch(openUploadDocumentModalAction({ data: false, path: "document" }));
    setIsUploadingDoc(false);
    setDocUploadIndex(0);
    setDocFileIndex(0);
    dispatch(getProfileDetails());
  };

  return {
    isDraggingOverDoc,
    docUploadState,
    documents,
    duplicateDocuments,
    invalidDocuments,
    allDocuments,
    newDocuments,
    docUploadIndex,
    isUploadingDoc,
    docFileIndex,
    docLength,
    closeWhenDoneDoc,
    handleImageChangeDoc,
    handleDragOverDoc,
    handleDragOutDoc,
    handleDropDoc,
    closeDocModal,
    openDocFiles,
    handleCloseDocMiniModal,
    closeDocUploadModalWhenDone,
    isDocLimit,
    uploadValidation,
  };
};
