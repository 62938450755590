import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAlbumItem,
  handleSelectedAlbum,
  loadMoreAlbumDetailImages,
  rearrange,
  reArrangeAlbum,
} from "../../../store/slices/Album/AlbumDetails";
import {
  currentAlbumId,
  endTabSession,
  clearTabState,
} from "../../../store/slices/GalleryTabSlice";
import { frameList } from "../../../store/slices/frameSlice";
import { useState } from "react";
import { getAxiosInstance } from "../../../api";

export const useAlbumView = () => {
  const dispatch = useDispatch();
  const AlbumDetails = useSelector((state) => state?.albumDetails);
  const selections = useSelector(
    (state) => state.galleryTab.ImageAlbumDetailsTab
  );

  const albumAddToSelection = useSelector(
    (state) => state.galleryTab.ImageAlbumAddToTab
  );

  const frames = useSelector((state) => state?.frames);

  const { albumId } = useParams();

  const [openShare, setOpenShare] = useState(false);
  const [token, setToken] = useState("");
  const [expiresAt, setExpiresAt] = useState("");

  useEffect(() => {
    if (frames?.status == "idle") {
      dispatch(frameList());
    }
  }, []);

  useEffect(() => {
    let obj = {
      page: "1",
      slug: "pos",
      id: albumId,
    };
    getAlbum(obj);
    dispatch(handleSelectedAlbum(albumId));
    dispatch(currentAlbumId(albumId));

    return () => {
      dispatch(clearTabState());
    };
  }, []);

  const rearrangeItems = (dragIndex, dropIndex) => {
    let imgData = AlbumDetails?.resultData?.data?.[albumId].images;
    const dragItem = imgData[dragIndex];
    const hoverItem = imgData[dropIndex];
    let changeArray = () => {
      const updatedImages = [...imgData];
      updatedImages[dragIndex] = hoverItem;
      updatedImages[dropIndex] = dragItem;
      handleReArrange(updatedImages, dragItem, hoverItem);
    };
    changeArray();
  };

  const handleReArrange = (data, draggedItem, changedItem) => {
    let obj = [
      {
        image_id: draggedItem?.id,
        position: changedItem?.position,
        album_id: albumId,
      },
      {
        image_id: changedItem?.id,
        position: draggedItem?.position,
        album_id: albumId,
      },
    ];
    if (changedItem?.position !== draggedItem?.position) {
      dispatch(reArrangeAlbum({ data: obj }));
    }
    dispatch(
      rearrange({
        data: data,
        draggedItem: draggedItem,
        changedItem: changedItem,
      })
    );
  };

  const getAlbum = async (obj) => {
    const resultAction = await dispatch(getAlbumItem(obj));
    if (getAlbumItem.fulfilled.match(resultAction)) {
      //dispatch(updateAllPageStatus("loading"));
      loadMore(resultAction?.payload?.data?.result);
    }
  };

  const loadMore = async (Album) => {
    if (Album?.current_page < Album?.last_page) {
      let obj = {
        page: Album?.current_page + 1,
        slug: selections?.sort,
        id: albumId,
      };
      const resultAction = await dispatch(loadMoreAlbumDetailImages(obj));
      if (loadMoreAlbumDetailImages.fulfilled.match(resultAction)) {
        loadMore(resultAction?.payload?.data?.result);
      }
    }
  };

  const handleShareAlbum = () => {
    setOpenShare(true);
    // generateToken(expiresAt)
  };

  const handleDuration = (value) => {
    setExpiresAt(value);
    generateToken(value);
  };

  const generateToken = async (expiresAtValue) => {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/token-generate?expired_at=${expiresAtValue}&album_id=${albumId}`
    );
    if (res.status == 200) {
      setToken(res?.data?.result);
    }
  };

  const nodata = {
    true: "You haven’t added any Photos",
    false: "You haven’t added any Documents",
  };

  const NodataContent = {
    title: `You haven’t added any ${
      AlbumDetails?.resultData?.data?.[albumId]?.album?.default
        ? "documents"
        : "photos"
    }`,
    description: `Upload your ${
      AlbumDetails?.resultData?.data?.[albumId]?.album?.default
        ? "documents"
        : "photos"
    } now to bring your family story to life.`,
    buttonText: `Upload Photos`,
  };

  return {
    albumAddToSelection,
    selections,
    status: AlbumDetails?.status,
    data: AlbumDetails?.resultData?.data?.[albumId],
    NodataContent,
    rearrangeItems,
    frames,
    // loadMore,
    openShare,
    setOpenShare,
    token,
    handleShareAlbum,
    handleDuration,
    albumId,
  };
};
