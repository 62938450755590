import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  trashPageStatus: "loading",
  isDeleting: "idle",
  error: null,
  resultData: {},
};

export const getTrashList = createAsyncThunk(
  "trash/getTrashList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `trashed-images?page=${params.page}&&sort=${params.slug}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeTrash = createAsyncThunk(
  "trash/removeTrash",

  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`restore-image`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTrash = createAsyncThunk(
  "trash/deleteTrash",

  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`delete-trashed-images`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoadmoreImages = createAsyncThunk(
  "trash/LoadmoreImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `trashed-images?page=${params.page}&&sort=${params.slug}&per_page=100`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const trashSlice = createSlice({
  name: "trash",
  initialState,
  reducers: {
    updateTrashPageStatus: (state, action) => {
      state.trashPageStatus = action.payload;
    },
  },

  extraReducers: {
    [getTrashList.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getTrashList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.resultData = action.payload.data.result;
    },
    [getTrashList.rejected]: (state, action) => {
      state.status = "failed";
    },

    [removeTrash.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [removeTrash.fulfilled]: (state, action) => {
      state.status = "succeeded";
      let images = [...state.resultData.data];
      state.resultData = {
        ...state.resultData,
        // data: images.filter(
        //   (item, index) => !action.payload.data?.result?.includes(item.id)
        // ),
        total: state.resultData.total - action.payload.data.result?.length,
      };
    },
    [removeTrash.rejected]: (state, action) => {
      state.status = "failed";
    },

    [LoadmoreImages.fulfilled]: (state, action) => {
      state.loadMore = "succeded";
      state.resultData = {
        data: [
          ...state.resultData?.data,
          ...action?.payload?.data?.result?.data,
        ],
        current_page: action?.payload?.data?.result?.current_page,
        total: action?.payload?.data?.result?.total,
        sorting_options: [...state.resultData?.sorting_options],
        last_page: action?.payload?.data?.result?.last_page,
      };
    },

    [deleteTrash.pending]: (state, action) => {
      state.isDeleting = "loading";
    },
    [deleteTrash.fulfilled]: (state, action) => {
      state.isDeleting = "succeded";
      let images = [...state.resultData.data];
      state.resultData = {
        ...state.resultData,
        data: images.filter(
          (item, index) => !action.payload.data.result.includes(item.id)
        ),
        total: state.resultData.total - action.payload.data.result?.length,
      };
    },
    [deleteTrash.rejected]: (state, action) => {
      state.isDeleting = "failed";
    },

    ["auth/logout"]: (state, action) => {
      state.status = "idle";
      state.resultData = {};
    },

    ["galleryImages/RemoveImages/fulfilled"]: (state, action) => {
      if (state.resultData.data) {
        state.resultData = {
          ...state.resultData,
          data: [...action?.meta?.arg?.data, ...state.resultData.data],
          total: state.resultData.total + action?.meta?.arg?.data?.length,
        };
      }
    },
  },
});

export const { updateTrashPageStatus } = trashSlice.actions;

export default trashSlice.reducer;
