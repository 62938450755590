import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DeSelectImage,
  SelectMultImage,
} from "../../../../store/slices/GalleryTabSlice";
import {
  addToStared,
  getStaredList,
} from "../../../../store/slices/ProfileGallery/profileGallerySlice";
import { useStaredFavorites } from "../../../StaredFavorite/useStaredFavorite";

export const useFilter = () => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const selections = useSelector((state) => state.galleryTab);

  const profileGallery = useSelector(
    (state) => state.profileGallery.resultData
  );
  const Favorites = useSelector((state) => state.favorite.resultData);
  const { favorites } = useStaredFavorites();

  const isNotAllSelected =
    favorites?.resultData?.data?.length !==
    selections?.ImageProfileTab?.selectedImage.length;

  const handleSelectAll = () => {
    if (selectAll && !isNotAllSelected) {
      setSelectAll(false);
      dispatch(DeSelectImage({ tab: "user-profile" }));
    } else {
      let ImageIds = Favorites?.data.map((obj) => obj.id);
      dispatch(SelectMultImage({ tab: "user-profile", ImageIds }));
      setSelectAll(true);
    }

    //FIXME when image paginate need to handle
  };

  const handleClose = () => {};

  const handleAddToStaredImages = async () => {
    let image_id = selections.ImageProfileTab.selectedImage;

    if (image_id?.length > 0) {
      const resultAction = await dispatch(addToStared({ image_id }));

      if (addToStared.fulfilled.match(resultAction)) {
        if (image_id?.length >= 50 || profileGallery?.data?.length >= 50) {
          toast.warning("You can only select up to 50 images.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getStaredList());
        } else {
          toast.success("successfully added to stared", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getStaredList());
        }
      }
    } else {
      toast.warning("Select at least one image.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return {
    selectAll,
    isNotAllSelected,
    handleSelectAll,
    handleClose,
    handleAddToStaredImages,
  };
};
