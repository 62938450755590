import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { endTabSession } from "../../store/slices/GalleryTabSlice";
import {
  getTrashList,
  LoadmoreImages,
  updateTrashPageStatus,
} from "../../store/slices/Trash/trashSlice";

export const useTrash = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const Trash = useSelector((state) => state?.trash);
  const selections = useSelector((state) => state.galleryTab);

  let currentPath = location.pathname
    .replace("/dashboard", "")
    .replaceAll("/", "");

  useEffect(() => {
    // if (Trash?.status == 'idle') {
    let obj = {
      page: "1",
      slug: selections?.ImageTrashTab?.sort,
    };

    getAlltrash(obj);
    // }

    return () => {
      dispatch(endTabSession({ tab: currentPath }));
    };
  }, []);

  const getAlltrash = async (obj) => {
    const resultAction = await dispatch(getTrashList(obj));
    if (getTrashList.fulfilled.match(resultAction)) {
      dispatch(updateTrashPageStatus("loading"));
      loadMore(resultAction?.payload?.data?.result);
    }
  };

  const loadMore = async (Images) => {
    if (Images?.current_page < Images?.last_page) {
      let obj = {
        page: Images?.current_page + 1,
        slug: selections?.ImageTrashTab?.sort,
      };
      const resultAction = await dispatch(LoadmoreImages(obj));
      if (LoadmoreImages.fulfilled.match(resultAction)) {
        loadMore(resultAction?.payload?.data?.result);
      }
    } else if (Images?.current_page === Images?.last_page) {
      dispatch(updateTrashPageStatus("succeded"));
    }
  };

  const NodataContent = {
    title: `Your trash is empty`,
    description: `You haven’t deleted any photos. Once you delete photos, they'll appear here.`,
    // buttonText: `Empty Trash` (if needed for future functionality)
  };

  return {
    status: Trash?.status,
    Trash,
    // loadMore,
    NodataContent,
  };
};
