import Style from "./ImageThumb.module.scss";
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import useThumb from "./useThumb";
import useGallery from "../../../../Logic/useGallery";
import Icons from "../../../Layouts/Icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CommonModal from "../../../Modals/commonModal";
import useAlbum from "../../../../Logic/useAlbum";
import Img from "react-cool-img";
import useCustomRightClick from "../../../../Logic/useCustomRightClick";
import { InView, useInView } from "react-intersection-observer";
import useDarkmode from "../../../../utils/utilityHooks/useDarkmode";
import HeartIcon from "../../../../utils/SvgIcons/heartIcon";
import { useDimensions } from "../../../../Logic/Dimensions";
import ImageDeleteModal from "../../../ImageDeleteModal";
import { useParams } from "react-router-dom";
import DeleteAlbumImageModal from "../../GalleryFilters/RightFilteredCategory/DeleteAlbumImageModal";

const ImageThumb = ({
  image,
  i,
  moveListItem,
  handleOpenImageFullView,
  frame,
  albumData,
  type,
  hasTray,
  options,
  mode,
  disableControls,
  isShare,
}) => {
  const windowDimensions = useDimensions();
  const { albumId } = useParams();

  const someRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const {
    toBeDragged,
    setToBeDragged,
    handleMouseDown,
    handleMouseUp,
    handleClickFavorite,
    handleRemoveFromFavorites,
    DeleteModal,
    setDeleteModal,
    handleDeleteImage,
    location,
    selections,
    handleImageSelect,
    showFullView,
    handleUnArchiveImage,
    handleRestoreImage,
    deleteAlbumImageModal,
    setDeleteAlbumImageModal,
  } = useThumb({ handleOpenImageFullView, albumData });

  const { theme } = useDarkmode();
  const { sections, currentTab, allImageStatus, faileditems } = useSelector(
    (state) => state.gallery
  );
  const [onMouseHover, setOnMouseHover] = useState(false);
  const [onLoadState, setOnLoadState] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [containerHeight, setContainerHeight] = useState(null);

  let height;
  let width;
  const handleLoad = (e) => {
    height = e.target.offsetHeight;
    width = e.target.offsetWidth;
    setContainerHeight((height / width) * 100);
    setOnLoadState(true);
    setImageLoading(false);
  };

  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { i },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [spec, dropRef] = useDrop({
    accept: "item",
    spec: { index: i },
    drop: (item) => {
      moveListItem(item.i, i);
    },
  });

  useEffect(() => {
    if (!isDragging) {
      setToBeDragged(false);
    }
  }, [isDragging]);

  const handleMouseEnter = () => {
    if (!sections[currentTab]?.currentActions?.preselect) {
      if (!sections[currentTab]?.currentActions?.rearrange) {
        setOnMouseHover(true);
      }
    }
  };

  const handleMouseLeave = () => {
    if (!sections[currentTab]?.currentActions?.preselect) {
      setOnMouseHover(false);
    }
  };

  let newImgRatio = (image?.height / image?.width) * 100;

  let tempCard =
    theme !== "dark"
      ? "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3e%3crect id='Rectangle_22157' data-name='Rectangle 22157' width='12' height='12' fill='%23e8e8e8'/%3e%3c/svg%3e"
      : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3e%3crect id='Rectangle_22157' data-name='Rectangle 22157' width='12' height='12' fill='%233a3a3a'/%3e%3c/svg%3e";

  let isMobileView = windowDimensions.width <= 1279;

  const selectedArray = () => {
    switch (location.pathname) {
      case "/dashboard":
        return selections?.ImageAllTabSelection;
      case "/dashboard/slideshow":
        return selections?.AddSlideShowSelection;
      case "/dashboard/favorites":
        return selections?.ImageFavoriteTab;
      case "/dashboard/album":
        return selections?.ImageAlbumTab;
      case "/dashboard/hidden":
        return selections?.ImageArchiveTab;
      case "/dashboard/trash":
        return selections?.ImageTrashTab;
      case "/dashboard/user-profile":
        return selections?.ImageTrashTab;
      case `/dashboard/album/${albumId}`:
        return getAlbumStateData();
    }
  };
  const getAlbumStateData = () => {
    return selections?.ImageAlbumAddToTab?.selction
      ? selections?.ImageAlbumAddToTab
      : selections?.ImageAlbumDetailsTab;
  };
  const isPathForDeletion = (path) => {
    let check = false;
    if (path == "/dashboard/favorites") {
      check = true;
    } else if (path == "/dashboard/hidden") {
      check = true;
    } else if (path == "/dashboard/trash") {
      check = true;
    } else if (path == "/dashboard/user-profile") {
      check = true;
    }
    return check;
  };

  const isPathForFav = (path) => {
    let check = false;
    if (path == "/dashboard/hidden") {
      check = true;
    } else if (path == "/dashboard/trash") {
      check = true;
    } else if (path == "/dashboard/user-profile") {
      check = true;
    }
    return check;
  };

  const PreSelection = (image) => {
    return (
      // currentTab=='dashboard/slideshow'&&image.
      <>
        {selectedArray()?.selction == true &&
          !selectedArray()?.selectedImage?.includes(image?.id) && (
            <div className="selection" />
          )}
        {selectedArray()?.selction == true &&
          selectedArray()?.selectedImage?.includes(image?.id) && (
            <div className={Style.gallery_select_tick}>
              <Icons icon={"icon-tick"} size={15} />
            </div>
          )}
      </>
    );
  };

  const checkView = () => {
    let view = false;
    if (options?.isRearrange) {
      view = true;
    } else {
      view = inView;
    }
    return view;
  };
  let ImageData =
    image?.file_type == 1
      ? image?.src?.encoded
      : image?.file_type == 2
      ? image?.src?.preview_url
      : image?.src?.encoded;
  return (
    <>
      <div
        className={`${Style.drop_container} ${
          mode == "album" &&
          albumData?.frames?.name !== "None" &&
          Style.album_contianer
        }`}
        ref={options?.isRearrange ? dropRef : ref}
        style={{
          opacity: isDragging ? 0 : 1,
          position: "relative",
        }}
        key={i}
      >
        <div
          className={`${Style.gallery_image_cont} 
        
           ${
             image.favourite == 1 && `${Style.favorite_active} favorite-active`
           }`}
          style={{
            paddingBottom: `${albumData?.is_document == 1 ? 75 : newImgRatio}%`,
          }}
        >
          <figure
            className={`${Style.gallery_image_wrap} ${
              albumData?.frames?.name !== "None" && frame && Style.frameFit
            } ${
              selections?.ImageAllTabSelection?.selectedImage?.includes(
                image?.id
              )
                ? Style.image_error
                : ""
            } ${onMouseHover ? Style.onHover_figure : ""} ${
              !onLoadState ? "img-loading" : "img-loaded"
            } ${!hasTray ? Style.no_tray : ""} ${
              onLoadState && newImgRatio ? Style.imageRatio : ""
            }  ${albumData?.is_document == 1 && Style.DocImage}`}
            style={{
              borderImageSource:
                albumData?.frames?.name !== "None" && `url(${frame})`,
            }}
          >
            {!(currentTab == "slideshow" && albumData?.default) &&
              PreSelection(image)}

            <img
              style={{
                transform:
                  albumData?.crop_value > 0 &&
                  ` scale(${albumData?.crop_value})`,
              }}
              ref={options?.isRearrange ? dragRef : ref}
              className={` ${
                image?.src?.thumbnail1 ? Style.placeholder_image_active : ""
              } ${currentTab == "all" && Style.galleryMode}`}
              onClick={() => handleImageSelect(image, i)}
              src={checkView() ? ImageData : tempCard}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}
              onMouseDown={() => options?.isRearrange && handleMouseDown()}
              onMouseUp={handleMouseUp}
              onLoad={(e) => handleLoad(e)}
            />
            {image?.src?.thumbnail1 && hasTray && !isMobileView ? (
              <div
                className={`${Style.gallery_image_tray} ${
                  sections[currentTab]?.currentActions?.preselect ||
                  sections[currentTab]?.currentActions?.preSlectionAlbumItem
                    ? "d-none"
                    : ""
                } image_tray`}
                style={{ display: toBeDragged ? "none" : "flex" }}
              >
                <div className={Style.tray_details}>
                  <div className={Style.gallery_image_tray_author}>
                    {image?.user?.username}{" "}
                  </div>
                  <div
                    className={`${Style.gallery_image_tray_post_date} post-date-wrap`}
                  >
                    {image?.metadata?.file_date_time ? (
                      <>
                        <span className="post-date-label">Captured on:</span>{" "}
                        <span className="post-date">
                          {moment(image?.metadata?.file_date_time).format(
                            "DD MMM YYYY hh:mm A"
                          )}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {!albumData?.photofind_album ? (
                  <div className={Style.tray_actions}>
                    {!disableControls && (
                      <>
                        {selectedArray()?.selction != true &&
                          !isPathForFav(location.pathname) && (
                            <button
                              className={`${Style.tray_actions_btn} ${Style.favorite_btn} `}
                              onClick={() =>
                                image.favourite == 1
                                  ? handleRemoveFromFavorites(image?.id)
                                  : handleClickFavorite(image)
                              }
                              onMouseEnter={() => handleMouseEnter()}
                              onMouseLeave={() => handleMouseLeave()}
                            >
                              {HeartIcon}
                            </button>
                          )}

                        {selectedArray()?.selction != true &&
                          !isPathForDeletion(location.pathname) && (
                            <button
                              className={Style.tray_actions_btn}
                              onClick={() => handleDeleteImage(image?.id)}
                              onMouseEnter={() => handleMouseEnter()}
                              onMouseLeave={() => handleMouseLeave()}
                            >
                              <Icons icon={"icon-trash"} size={16} />
                            </button>
                          )}

                        {selectedArray()?.selction != true &&
                          location.pathname == "/dashboard/hidden" && (
                            <button
                              className={Style.tray_actions_btn}
                              onClick={() => handleUnArchiveImage(image?.id)}
                              onMouseEnter={() => handleMouseEnter()}
                              onMouseLeave={() => handleMouseLeave()}
                            >
                              <Icons icon={"unarchive-stroke"} size={14} />
                              <h6>Unhide</h6>
                            </button>
                          )}

                        {selectedArray()?.selction != true &&
                          location.pathname == "/dashboard/trash" && (
                            <button
                              className={Style.tray_actions_btn}
                              onClick={() => handleRestoreImage(image?.id)}
                              onMouseEnter={() => handleMouseEnter()}
                              onMouseLeave={() => handleMouseLeave()}
                            >
                              <Icons icon={"restore_page"} size={14} />
                              <h6>restore</h6>
                            </button>
                          )}
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </figure>
        </div>
        <div></div>
      </div>
      <ImageDeleteModal
        DeleteModal={DeleteModal}
        setDeleteModal={setDeleteModal}
      />
      <DeleteAlbumImageModal
        deleteModal={deleteAlbumImageModal}
        setDeleteModal={setDeleteAlbumImageModal}
        path={location.pathname}
        albumId={albumId ? albumId : albumData?.id}
      />
    </>
  );
};

export default ImageThumb;
