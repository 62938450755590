import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateEditedImageToState } from "../store/slices/GalleryImagesSlice";
import { addEditedImageToState } from "../store/slices/gallerySlice";
import {
  getMediaURLs,
  saveMedia,
  saveAlbumEditMedia,
} from "../store/slices/imageEditSlice";
import { getProfileDetails } from "../store/slices/userprofileSlice";

const useImageEditComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { path, itemId, image_id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [imageData, setImageData] = useState("");
  const { userdata } = useSelector((state) => state.userprofile);

  useEffect(() => {
    dispatch(getMediaURLs(image_id))
      .unwrap()
      .then((data) => {
        setImageData(data?.data?.result.src?.normal);
      });
  }, []);

  var contentType = "image/png";

  const b64toBlob = (b64) => {
    var img = new Image();
    var blobPromise;
    var canvas = document.createElement("canvas");
    let imgPromise = new Promise(function (resolve, reject) {
      // img.onerror = onerror;
      img.onload = function onload() {
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          function (blob) {
            resolve(blob);
          },
          "image/jpeg",
          1
        );
      };

      img.src = b64;
    });

    return imgPromise;
  };

  const saveImage = (editedData) => {
    setIsSaving(true);
    b64toBlob(editedData?.imageBase64, contentType).then((blob) => {
      let data = {
        path: path,
        blob: blob,
        albumId: itemId,
      };
      if (userdata?.percentage >= 100) {
        toast.error("Image Edit Failed!, Your storage is full.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
        setIsSaving(false);
      } else {
        dispatch(saveMedia(data))
          .unwrap()
          .then((res) => {
            setIsSaving(false);
            if (res.data.success) {
              if (path == "gallery") {
                dispatch(updateEditedImageToState(res.data.result));
              }
              dispatch(getProfileDetails());
              toast.success("Image Edited Successfully!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate(-1);
            }
          })
          .catch((error) => {
            setIsSaving(false);
            toast.error("Image Edit Failed !", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    });
  };

  return {
    isSaving,
    setIsSaving,
    imageData,
    setImageData,
    saveImage,
  };
};
export default useImageEditComponent;
