import React, { useState, useEffect, useRef } from "react";
import Style from "../EditProfilePage.module.scss";
import Assets from "../../Layouts/Assets";
import { useFormik } from "formik";
import { Formik, Field, Form } from "formik";
import { usePhoneUpdateOtp } from "../../../Logic/usePhoneUpdateOtp";
import OtpInput from "react-otp-input";

function ComponentPhoneUpdateOTP() {
  const {
    verifyForgotOtp,
    code,
    showOtpResponse,
    verifyOtpStatus,
    minutes,
    seconds,
    otpCounter,
    resendOTp,
  } = usePhoneUpdateOtp();

  return (
    <Formik initialValues={{}} onSubmit={(values, { resetForm }) => {}}>
      {({
        errors,
        values,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <>
            <div className={Style.edit_profile_info_form}>
              {verifyOtpStatus ? (
                <>
                  <h3 className={Style.edit_profile_info_title}>Success</h3>
                  <p>Phone number has been updated successfully.</p>
                </>
              ) : (
                <>
                  <h3 className={Style.edit_profile_info_title}>
                    OTP Sent to Your Number
                  </h3>
                  <div className="form-group">
                    <label htmlFor="input_test">OTP</label>
                    <OtpInput
                      value={code}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      name="otp"
                      onChange={(e) => {
                        verifyForgotOtp(e, "forgot_password");
                        setFieldValue("otp", e);
                      }}
                      numInputs={4}
                      onBlur={handleBlur}
                      inputStyle={`${
                        showOtpResponse?.status == false
                          ? Style.input_Style_error
                          : ""
                      }`}
                      className="otp-field"
                    />
                  </div>
                  <p>
                    {showOtpResponse?.status == false
                      ? showOtpResponse.message
                      : ""}
                  </p>
                  {otpCounter > 0 ? (
                    <>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </>
                  ) : (
                    <div className={Style.modal_footer_btn}>
                      <span>OTP not received?</span>
                      <button onClick={(e) => resendOTp(values)}>Resend</button>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default ComponentPhoneUpdateOTP;
