import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import FamilyTree from "@balkangraph/familytree.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAxiosInstance } from "../../api";
import { useReactToPrint } from "react-to-print";
import {
  getCurrentVersionHistory,
  getFamilyDetails,
} from "../../store/slices/familyTreeSlice";
import addImg from "../../assets/add-button.png";

const SAMPLE_DATA = [
  {
    id: 1,
    connection: "user",
    family_name: "",
    name: "Sooraj",
    mid: null,
    fid: null,
    dob: "1951-03-30 00:00:00",
    pids: [],
    gender: 1,
    img: "https://api.familyphotosalbum.com/storage/thumbnail/thumbnail20221221120010XCJxo.",
    bio: null,
    family_member_id: 249,
  },
];

const UseFamilyTree = ({ divRef, componentRef }) => {
  const [size, setSize] = useState([0]);
  const [myData, setData] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [active, setActive] = useState(false);
  const [newData, setNewData] = useState([]);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState([SAMPLE_DATA[0]]);
  const [defaultView, setDefaultView] = useState("");
  const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [showProfileUploadModal, setShowProfileUploadModal] = useState(false);
  const [acceptedRemoval, setAcceptedRemoval] = useState(false);
  const [reInitTree, setReInitTree] = useState(false);
  const [removable, setRemovable] = useState(false);
  const [clearTreeModal, setClearTreeModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let exportPDF;
  const navigate = useNavigate();

  const userID = useSelector((state) => state.auth.registerData?.user_id);

  useEffect(() => {
    setDrawerData((state) => {
      return { ...state, user_id: userID };
    });
  }, [userID]);

  const dispatch = useDispatch();

  const handleDragOver = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  // window.handleDrop = handleDrop;
  window.handleDragOver = handleDragOver;
  window.setShowProfileUploadModal = setShowProfileUploadModal;

  useEffect(() => {
    const getUserData = async () => {
      const resultAction = await dispatch(getFamilyDetails());
      if (getFamilyDetails.fulfilled.match(resultAction)) {
        const resData = resultAction.payload?.data?.result;
        setData(resData);
        if (resData?.length === 0) {
          navigate("/family-tree-form");
        }
      } else {
      }
    };
    getUserData();
    // setData(SAMPLE_DATA);
  }, []);

  const handleUpdateVersionData = async (item) => {
    const resultAction = await dispatch(getCurrentVersionHistory(item?.id));
    if (getCurrentVersionHistory.fulfilled.match(resultAction)) {
      const resData = resultAction.payload?.data?.result;

      let updatedData = resData.map(({ image, ...rest }) => {
        return {
          ...rest,
          img: image,
        };
      });
      setData(resData);
      if (resData?.length === 0) {
        navigate("/family-tree-form");
      }

      // setData(resData);
      // if (resData?.length === 0) {
      //   navigate("/family-tree-form");
      // }
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
  }, []);

  const getArry = (arry) => {
    let count = arry?.length;
    let newArry = [];
    for (let i = 0; i < count; i += 2) {
      let obj = [arry[i], arry[i + 1]];
      newArry.push(obj.join(" ").replace(",", ""));
    }

    return newArry;
  };

  const isSafari = () => {
    if (navigator.vendor.match(/[Aa]+pple/g)?.length > 0) {
      return true;
    } else if (navigator?.userAgentData?.platform == "macOS") {
      return true;
    }
    return false;
  };

  let family = useMemo(() => {
    FamilyTree.templates.myTemplate = Object.assign(
      {},
      FamilyTree?.templates?.tommy
    );
    FamilyTree.templates.myTemplate.size = [168, 236];
    FamilyTree.templates.myTemplate.node = "";
    FamilyTree.templates.myTemplate.html = "{val}";
    FamilyTree.templates.myTemplate_male = Object.assign(
      {},
      FamilyTree.templates.myTemplate
    );
    FamilyTree.templates.myTemplate_female = Object.assign(
      {},
      FamilyTree.templates.myTemplate
    );

    //assign template
    let tree;
    if (divRef.current) {
      tree = new FamilyTree(divRef.current, {
        exportUrl: "https://familyphotosalbum.com/export",
        nodes: myData,
        enableSearch: false,
        template: "myTemplate",
        scaleInitial: zoom,
        zoom: { smooth: 30, speed: isSafari() ? 10 : 50 },
        levelSeparation: 70,
        siblingSeparation: 70,
        nodeMouseClick: FamilyTree.action.none,
        // mouseScrool: FamilyTree.action.zoom,
        // collapse: { level: 3 },
        nodeBinding: {
          html: "html",
        },
        exportPDF: exportPDF,
        sticky: false,
      });
    }

    return tree;
  }, [myData, reInitTree, zoom]);

  family?.on("field", function (semder, args) {
    let user = myData?.filter((d) => d.connection == "user");
    let userHasGender = user[0]?.gender;
    let string_arry = args?.data?.name?.split(" ");
    let renderTexts = getArry(string_arry)?.map((item, index) => {
      return `<tspan x="0" y="0">${item}</tspan>`;
    });

    // let  wordBreak =
    if (args.name == "html") {
      if (args.data.name == "") {
        args.value = `
        <g onDrop="handleDrop(event)" onDragOver="handleDragOver(event)" class="card-item">
        <svg id="Group_44986" data-name="Group 44986" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="158.555" height="222.363" viewBox="0 0 158.555 222.363">
        <defs>
          <clipPath id="clip-path">
            <circle id="Ellipse_39787" data-name="Ellipse 39787" cx="66.709" cy="66.709" r="66.709" fill="#f2f3f5"/>
          </clipPath>
        </defs>
        <rect id="Rectangle_16507" data-name="Rectangle 16507" width="158.555" height="222.363" rx="10" fill="#fff"/>
        <text id="Mathew_Alex" data-width="130"   data-text-overflow="multiline"  data-name="Mathew Alex" transform="translate(77.738 169.588)" fill="#252533" font-size="13" font-family="Ubuntu-Bold, Ubuntu" font-weight="700"><tspan x="-40.729" y="0">Add Relative</tspan></text>
        <text id="Kalathilparambil" data-width="130"   data-text-overflow="multiline"  transform="translate(76.771 188.857)" fill="#767678" font-size="11" font-family="Ubuntu"><tspan x="-30.92" y="0">Family Name</tspan></text>
        <g id="Mask_Group_25379" data-name="Mask Group 25379" transform="translate(12.085 12.568)" clip-path="url(#clip-path)">
        <image id="Image_1972" data-name="Image 1972" width="235.119" height="156.621" transform="translate(-47.473, -4)" xlink:href=${addImg}></image>
        </g>
        </svg>
     
        </g>
          `;
      } else {
        args.value = `
        <g onDrop="handleDrop(event, ${
          args.data.id
        })"  onDragOver="handleDragOver(event)" class="card-item ${
          args.data.id === 1
            ? `user`
            : args.data.connection === "pet"
            ? "lock-user"
            : ""
        }"> <svg id="Group_44986" data-name="Group 44986" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${
          args.node.w
        }" height="${args.node.h}" viewBox="0 0 ${args.node.w} ${args.node.h}">
        <defs>
          <clipPath id="clip-path">
            <circle id="Ellipse_39787" data-name="Ellipse 39787" cx="66.709" cy="66.709" r="66.709" fill="#f2f3f5"/>
          </clipPath>
        </defs>

        
          <rect width="${args.node.w}" height="${
          args.node.h
        }" rx="10" stroke="none" fill="none"/>
        <rect x="1.5" y="1.5" width="${args.node.w - 3}" height="${
          args.node.h - 3
        }" rx="8.5" fill="none" class="${args.data.id === 1 ? `user` : ""}"/>

  
        <text  id="Mathew_Alex" data-width="230"  data-text-overflow="multiline-2" text-anchor="middle" data-name="Mathew Alexs" transform="translate(83.738 169.588)" fill="#252533" font-size="14" font-family="Ubuntu-Bold, Ubuntu" font-weight="700">
         <tspan x="0" y="15">${
           args?.data?.name ? args?.data?.name?.substring(0, 16) : ""
         }${args?.data?.name?.length >= 16 ? "..." : ""}</tspan>
        </text>
        <text id="Kalathilparambil"  data-width="130"  data-text-overflow="ellipsis"  text-anchor="middle" transform="translate(82.771 188.857)" fill="#767678" font-size="11" font-family="Ubuntu">
        <tspan x="0" y="20">
        ${
          args?.data?.family_name
            ? args?.data?.family_name?.substring(0, 22)
            : ""
        }${args?.data?.family_name?.length >= 22 ? "..." : ""}
        </tspan>
        </text>
       
        <g id="Mask_Group_25379" data-name="Mask Group 25379" transform="translate(18.085 17.568)" clip-path="url(#clip-path)">
          <image id="Image_1972" data-name="Image 1972" width="235.119" height="156.621" transform="translate(-50.473)" xlink:href="${
            typeof args?.data?.img !== "string"
              ? URL.createObjectURL(args?.data?.img)
              : args?.data?.img
              ? args?.data?.img
              : "https://api.familyphotosalbum.com/image/default.svg"
          }"></image>
        
         
        
        </g>
        </svg> 
        <g class="text">
        <svg   x="50" y="230" xmlns="http://www.w3.org/2000/svg" width="64.078" height="31" viewBox="0 0 64.078 27">
        <g id="Group_61704"  data-name="Group 61704" transform="translate(6017.188 -3891.995)">
        ${
          userHasGender &&
          `<path id="Path_76516" data-name="Path 76516" d="M5082.695,5878.962l-1.2-.416a12.983,12.983,0,0,0-3.57-6.055,9.331,9.331,0,0,0-6.245-2.289c-1.322-.042-2.458,0-2.458,0v-4.031h14.562l.85,14.686Z" transform="translate(-11086.414 -1974.175)" fill="#fff"/>
          <path id="Rectangle_17311" class="add_btn_wrap" data-name="Rectangle 17311" d="M0,0H40.923a0,0,0,0,1,0,0V9a18,18,0,0,1-18,18H18A18,18,0,0,1,0,9V0A0,0,0,0,1,0,0Z" transform="translate(-6005.445 3891.995)" fill="#fff"/>
          <path id="Path_76517" data-name="Path 76517" d="M5071.168,5878.962l1.2-.416a12.984,12.984,0,0,1,3.57-6.055,9.33,9.33,0,0,1,6.245-2.289c1.322-.042,2.457,0,2.457,0v-4.031h-14.562l-.85,14.686Z" transform="translate(-11037.748 -1974.175)" fill="#fff"/>`
        }
        
        <g id="Group_46642" data-name="Group 46642" transform="translate(-5997.11 3889.995)">
        ${
          userHasGender &&
          `<circle id="Text_111" id="Ellipse_39855" data-name="Ellipse 39855" cx="12.5" cy="12.5" r="12.5" fill="#23cc2b"/>
          <g id="Text_111" data-name="Group 45553" transform="translate(7.409 7.409)">
          <path id="Text_111" data-name="Path 76518" d="M0,0H10.182" transform="translate(0 5.091)" fill="none" stroke="#fff" stroke-width="1.5"/>
          <line id="Text_111" data-name="Line 38426" x2="10.182" transform="translate(5.091 0) rotate(90)" fill="none" stroke="#fff" stroke-width="1.5"/>
          </g>`
        }
        </g>
        </g>
        </svg>
        </g>
        </g>
        `;
      }
    }
  });

  window.family = family;

  //click top open Popup
  family?.onNodeClick((props) => {
    setRemovable(family.canRemove(props.node.id));
    if (props.event?.target?.id === "Text_111") {
      setNewData([
        {
          userData: myData.filter((item) => item.id === props.node.id),
          allData: myData,
        },
      ]);
      setActive(!active);
    } else {
      setSideDrawerOpen(true);
      setDrawerData(myData.filter((item) => item.id === props.node.id));
    }
  });

  var handleNodeRemove = (id) => {
    let canRemove;
    let removeNode;
    let getNodes = () => family?.nodes;
    let nodes = null;
    if (getNodes()) {
      if (typeof family.canRemove == "function") {
        canRemove = family.canRemove(id);
      }
      if (typeof family.canRemove == "function") {
        removeNode = (updateNodeRemoval) => {
          family.removeNode(id, () => {
            updateNodeRemoval(family.nodes);
          });
        };
      }
    }
    return { canRemove, removeNode, getNodes };
  };
  const print = useReactToPrint({
    content: () => divRef.current,
  });
  const handlePrint = () => {
    handleRecenter();
    setTimeout(() => {
      print();
    }, 400);
  };

  const handleRecenter = () => {
    family.fit();
    setZoom(1);
  };

  const clearTree = async (finalDelete) => {
    if (finalDelete) {
      let api = await getAxiosInstance();
      let newData = await api.get("/delete-tree");
      setData([newData?.data?.result]);
      setClearTreeModal(false);
    } else {
      setClearTreeModal(true);
    }
  };

  return {
    size,
    myData,
    setData,
    zoom,
    setZoom,
    defaultView,
    setDefaultView,
    firstTime,
    setFirstTime,
    active,
    setActive,
    newData,
    setNewData,
    sideDrawerOpen,
    setSideDrawerOpen,
    drawerData,
    setDrawerData,
    showDeleteMemberModal,
    setShowDeleteMemberModal,
    handleRecenter,
    handlePrint,
    popUpData,
    setPopUpData,
    showProfileUploadModal,
    setShowProfileUploadModal,
    acceptedRemoval,
    setAcceptedRemoval,
    setReInitTree,
    handleNodeRemove,
    removable,
    clearTree,
    clearTreeModal,
    setClearTreeModal,
    loading,
    setLoading,
    handleUpdateVersionData,
  };
};

export default UseFamilyTree;
