import { useEffect, useState } from "react";
import {
  addMemberToTree,
  getGenderImage,
  getVersionHistories,
  removeMemberFromTree,
  updateProfilePicture,
} from "../../store/slices/familyTreeSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "../../store/slices/userprofileSlice";
import useDeleteRelations from "./useDeleteRelations";

const UseFamilyDrawer = (props) => {
  const {
    checkDrawerOpen,
    dataDrawer,
    mainData,
    setMainData,
    firstTime,
    setFirstTime,
    popUpData,
    setPopUpData,
    setLoading,
    removable,
  } = props;
  const [data, setData] = useState({
    id: "",
    mid: "",
    fid: "",
    pids: "",
    name: "",
    family_name: "",
    gender: "",
    dob: "",
    bio: "",
    img: "",
    connection: "",
    user_id: "",
    family_member_id: "",
  });
  const [formLoading, setFormLoading] = useState(false);
  const [showProfileUploadModal, setShowProfileUploadModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");

  const userID = useSelector((state) => state.auth.registerData?.user_id);
  const { defaultGenderImage } = useSelector((state) => state.familyTree);

  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultGenderImage?.length == 0) {
      dispatch(getGenderImage());
    }
  }, []);

  useEffect(() => {
    setData({
      id: dataDrawer[0].id ? dataDrawer[0].id : "",
      mid: dataDrawer[0].mid ? dataDrawer[0].mid : "",
      fid: dataDrawer[0].fid ? dataDrawer[0].fid : "",
      pids: dataDrawer[0].pids?.length > 0 ? dataDrawer[0].pids : [],
      name: dataDrawer[0].name ? dataDrawer[0].name : "",
      family_name: dataDrawer[0].family_name ? dataDrawer[0].family_name : "",
      gender: dataDrawer[0].gender ? dataDrawer[0].gender : "",
      dob: dataDrawer[0].dob ? dataDrawer[0].dob : "",
      bio: dataDrawer[0].bio ? dataDrawer[0].bio : "",
      img: dataDrawer[0].img ? dataDrawer[0].img : "",
      connection: dataDrawer[0].connection ? dataDrawer[0].connection : null,
      family_member_id: dataDrawer[0].family_member_id
        ? dataDrawer[0].family_member_id
        : "",
      user_id: userID,
    });
  }, [dataDrawer]);

  useEffect(() => {
    if (typeof data?.img !== "string" && data?.img !== "") {
      setFormLoading(true);
      dispatch(updateProfilePicture({ img: data.img }))
        .unwrap()
        .then((res) => {
          setFormLoading(false);
          setData((state) => {
            return {
              ...state,
              img: res?.data?.result?.img || "",
              large_thumbnail: res?.data?.result?.large_thumbnail,
              medium_thumbnail: res?.data?.result?.medium_thumbnail,
              thumbnail: res?.data?.result?.thumbnail,
              image: res?.data?.result?.image,
            };
          });
        });
    }
  }, [data.img]);

  useEffect(() => {
    let currImg = [];
    if (
      defaultGenderImage.length > 0 &&
      checkDefaulImage(dataDrawer[0].img) &&
      dataDrawer[0].connection != "pet"
    ) {
      currImg = defaultGenderImage?.filter((item) => item.key == data.gender);
      setData((s) => {
        return { ...s, img: currImg[0]?.value ? currImg[0]?.value : "" };
      });
    }
  }, [data.gender, dataDrawer]);

  const { dependencyCheck } = useDeleteRelations(data, mainData);

  useEffect(() => {
    const tempData = mainData;
    tempData.map((a, i) => {
      if (!Object.keys(a).includes("user_id")) {
        tempData[i] = { ...tempData[i], user_id: userID };
      }
    });
  }, [mainData]);

  //close drawer
  const handleClose = () => checkDrawerOpen(false);

  //handle Submit
  const handleSubmit = (e) => {
    if (popUpData?.length > 1) {
      setPopUpData([]);
      setLoading(true);
      dispatch(addMemberToTree({ data: [data, ...popUpData] }))
        .unwrap()
        .then((r) => {
          setMainData(r?.data?.result);
          checkDrawerOpen(false);
          setLoading(false);
        });
    } else {
      let tempData = JSON.parse(JSON.stringify(mainData));
      let index = tempData.findIndex((a) => a.id == data.id);
      let updatedTempData = tempData;
      updatedTempData[index] = data;

      if (index != "-1") {
        dispatch(addMemberToTree({ data: updatedTempData }))
          .unwrap()
          .then((r) => {
            setMainData(r?.data?.result);
            checkDrawerOpen(false);
            setLoading(false);
          });
        // setMainData(updatedTempData)
        // checkDrawerOpen(false);
      } else {
        dispatch(addMemberToTree({ data: [...popUpData, data] }))
          .unwrap()
          .then((r) => {
            setMainData(r?.data?.result);
            checkDrawerOpen(false);
            setLoading(false);
          });
        // setMainData([...popUpData, data])
        // checkDrawerOpen(false);
      }
      setPopUpData([]);
    }
    getVersions();
  };

  const getVersions = async () => {
    const resultAction = await dispatch(getVersionHistories());
    if (getVersionHistories.fulfilled.match(resultAction)) {
    }
  };

  // handle remove Node
  const removePerson = (e) => {
    e?.preventDefault();

    let { tempData, is_end_parent, is_end_child, is_end_partner } =
      dependencyCheck();

    if (is_end_parent || is_end_child || (is_end_partner && removable)) {
      dispatch(addMemberToTree({ data: tempData }));

      dispatch(removeMemberFromTree({ id: [data.family_member_id] }))
        .unwrap()
        .then(() => {
          setMainData(tempData);
          checkDrawerOpen(false);
        });
    }
  };

  window.removePerson = removePerson;

  //handle Values onChange
  const handleValue = (e) => {
    if (firstTime === true && e.target.name === "gender") {
      setFirstTime(false);
    }
    const { name, value } = e.target;
    if (name === "img") {
      setData({ ...data, [name]: e.target.files[0] });
    } else if (name === "gender") {
      setData({ ...data, [name]: parseInt(value) });
    } else if (name !== "img" && name !== "gender") {
      setData({ ...data, [name]: value });
    }
  };

  const handleRemoveImage = () => {
    setData({ ...data, img: "" });
  };

  const checkDefaulImage = (image) => {
    let index = defaultGenderImage.findIndex((obj) => obj.value == image);
    if (index > -1) {
      return true;
    }

    return false;
  };

  return {
    mainData,
    handleClose,
    handleSubmit,
    data,
    setData,
    handleValue,
    dataDrawer,
    removePerson,
    formLoading,
    setFormLoading,
    firstTime,
    setFirstTime,
    handleRemoveImage,
    dependencyCheck,
    showProfileUploadModal,
    setShowProfileUploadModal,
    checkDefaulImage,
  };
};

export default UseFamilyDrawer;
