import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDimensions } from "../../Logic/Dimensions";
import { Col, Row, Form } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import GalleryBlankPage from "../BlankGallery";
import Icons from "../Layouts/Icons";
import Style from "./Album.module.scss";
// import useAlbum from "../../Logic/useAlbum";
import CommonModal from "../Modals/commonModal";
import FrameItem from "./FrameItem";
import FitToFrame from "./FitToFrame/FitToFrame";
import useGallery from "../../Logic/useGallery";
import NewAlbumListView from "../NewAlbum/NewAlbumListView";
import NewAlbumGridView from "../NewAlbum/NewAlbumGridView";
import CreateSection from "./Modals/CreateSection";
import SlideShowFullViewTest from "../Slideshow/SlideShowFullViewTest";
import { useDispatch, useSelector } from "react-redux";
import { useKey, useSingleKey } from "../../utils/KeyBoardShortCuts";
import { useAlbumQuickViewer } from "../../Logic/AlbumHooks/useAlbumQuickViewer";
import { useAlbumLock } from "../../Logic/AlbumHooks/useAlbumLock";
import { useAlbum } from "./useAlbum";
import AlbumEditModal from "./AlbumEditModal";
import AlubmUnlockModal from "./AlbumUnlockModal";
import AlbumDeleteModal from "./AlbumDeleteModal";
import QuickSlideShow from "../Slideshow/QuickSlideShow";
import QuickSlideShow1 from "../Slideshow/QuickSlideShow1";
import { useLocation } from "react-router-dom";
import { DashboardLoader } from "../DashboardLoader";
import ShareModal from "../ShareModal";

const Album = ({ isSlideShow }) => {
  const location = useLocation();

  const {
    albums,
    selections,
    albumEditModal,
    setAlbumEditModal,
    handleClickEdit,
    unlockModal,
    setUnlockModal,
    handleAlbumLock,
    albumDeleteModal,
    setAlbumDeleteModal,
    handleClickDelete,
    moveAlbumItems,
    loadMore,
    openShare,
    setOpenShare,
    token,
    handleShareAlbum,
    handleDuration,
    seletedItemId,
  } = useAlbum();

  const { handleLock, selectedAlbumId } = useAlbumLock();

  const {
    quickSlideShow,
    currentSlideData,
    setCurrentSlideData,
    handleOpenSlideShowModal,
    closeQuickSlideShow,
    openSlideShow,
  } = useAlbumQuickViewer();

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  let slideData = {
    images: currentSlideData?.filter((item) => item?.file_type !== 2),
    effect: "zoom-out",
    music: quickSlideShow.musicList,
  };

  let slideShowData = isSlideShow
    ? albums?.resultData?.data?.filter((album) => !album.default)
    : albums?.resultData?.data;

  return (
    <div className={Style.album}>
      <div className={Style.album_container}>
        {location.pathname == "/dashboard/album" && (
          <>
            {!selections?.ImageAlbumTab?.grid ? (
              <NewAlbumListView
                data={albums?.resultData?.data}
                studio_name={albums?.resultData?.studio_name}
                handleOpenEditModal={handleClickEdit}
                handleOpenSlideShow={handleOpenSlideShowModal}
                handleUnLockAlbum={handleAlbumLock}
                handleLock={handleLock}
                selectedAlbumId={selectedAlbumId}
                handleOpenDelete={handleClickDelete}
                handleShareAlbum={handleShareAlbum}
              />
            ) : (
              <NewAlbumGridView
                albumData={albums?.resultData}
                data={albums?.resultData?.data}
                handleOpenEditModal={handleClickEdit}
                handleOpenSlideShow={handleOpenSlideShowModal}
                handleUnLockAlbum={handleAlbumLock}
                handleLock={handleLock}
                handleOpenDelete={handleClickDelete}
                moveAlbumItems={moveAlbumItems}
                options={selections?.ImageAlbumTab}
                handleShareAlbum={handleShareAlbum}
                // loadMore={loadMore}
              />
            )}
          </>
        )}

        {location.pathname == "/dashboard/slideshow" ? (
          <NewAlbumListView
            data={slideShowData}
            handleOpenEditModal={handleClickEdit}
            handleOpenSlideShow={handleOpenSlideShowModal}
            handleUnLockAlbum={handleAlbumLock}
            handleLock={handleLock}
            selectedAlbumId={selectedAlbumId}
            handleOpenDelete={handleClickDelete}
          />
        ) : (
          ""
        )}
      </div>

      {albums?.status == "loading" ? (
        <DashboardLoader data={albums?.resultData?.data} />
      ) : (
        ""
      )}
      <AlbumEditModal
        albumEditModal={albumEditModal}
        setAlbumEditModal={setAlbumEditModal}
      />
      <AlubmUnlockModal
        unlockModal={unlockModal}
        setUnlockModal={setUnlockModal}
      />
      <AlbumDeleteModal
        albumDeleteModal={albumDeleteModal}
        setAlbumDeleteModal={setAlbumDeleteModal}
      />

      {openSlideShow.open && (
        <QuickSlideShow1 slideObj={slideData} close={closeQuickSlideShow} />
      )}

      <ShareModal
        openShare={openShare}
        setOpenShare={setOpenShare}
        from="profile"
        secretToken={token}
        path={"albumshare"}
        handleDuration={handleDuration}
        albumId={seletedItemId}
      />
    </div>
  );
};

export default Album;
